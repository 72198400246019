<template>
  <div v-if="config.formLayout" class="quote-normal h-font-lg">
      <!--基本信息-->
      <div class="form quote-normal_basis">
        <div class="form-title">
          <van-row>
            <van-col span="24">
              <span class="icon" style="background-color:#07c160">基</span>
              <span>{{ config.formLayout['basis'].fmName }}</span>
            </van-col>
          </van-row>
        </div>
        <div class="form-content">
          <HYForm
            ref="basisFormRef"
            fmCode="basis"
            :config="config.formLayout['basis']"
            :form="pageForm['basis']"
          >
          </HYForm>
        </div>
      </div>
      <!--交强险-->
      <div class="form quote-normal_compulsory">
        <div class="form-title">
          <van-row>
            <van-col span="12">
              <span class="icon" style="background-color:#57ccc2">交</span>
              <span>{{ config.formLayout['compulsory'].fmName }}</span>
            </van-col>
            <van-col span="12">
              <span><van-checkbox v-model="pageForm.compulsory.checked" shape="square" @change="compulsoryCheck" icon-size="18px" checked-color="#ed6a0c" /></span>
            </van-col>
          </van-row>
        </div>
        <div class="form-content">
          <HYForm
            ref="compulsoryFormRef"
            fmCode="compulsory"
            :config="config.formLayout['compulsory']"
            :form="pageForm['compulsory']"
          >
          </HYForm>
        </div>
      </div>
      <!--商业险-->
      <div class="form quote-normal_commercial">
        <div class="form-title">
          <van-row>
            <van-col span="12">
              <span class="icon" style="background-color:#ff976a">商</span>
              <span>{{ config.formLayout['commercial'].fmName }}</span>
            </van-col>
            <van-col span="12">
              <span><van-checkbox @change="commercialCheck" v-model="pageForm.commercial.checked" shape="square" icon-size="18px" checked-color="#ed6a0c" /></span>
            </van-col>
          </van-row>
        </div>
        <div class="form-content">
          <HYForm
            ref="commercialFormRef"
            fmCode="commercial"
            :config="config.formLayout['commercial']"
            :form="pageForm['commercial']"
          >
          </HYForm>
        </div>
        <div v-if="config.riskLayout && pageForm.commercial.checked" :riskLayout="config.riskLayout">
          <RiskList ref="riskListRef" :layout="config.riskLayout" :riskForm="pageRiskForm" :pageForm="pageForm"/>
        </div>
      </div>
      <div class="quote-normal_bnt">
        <div class="quote-normal_bnt--fixed" >
          <van-button type="primary" block round @click="back()">确认</van-button>
        </div>
      </div>
  </div>
</template>
<script>
import HYForm from "@/components/Form.vue";
import RiskList from "../components/RiskList.vue";
import layout from "../layout/index";
import { mapState } from "vuex";
import { keys } from "lodash";
import { request } from "@/api/service";
import mixinRisk from '../common/mixin/risk';
import mixinCommon from '../common/mixin/common';
import Common from "@/api/common/index";
import { Notify,Dialog } from "vant";
import getCompanyData from '@/utils/setCompany'
import riskWatch from '@/utils/riskWatch'
export default {
  name: "RiskModel",
  components: { HYForm,RiskList },
  mixins: [mixinRisk,mixinCommon,riskWatch],
  provide() {
    return {
      pageContext: this,
      pageForm:this.pageForm,
      pageLayout:this.pageLayout
    };
  },
  data() {
    return {
      pageData: {
        submitLoading:false
      },
      config: {
        scriptConfig: null,
        formLayout: null,
        riskLayout:null
      },
    };
  },
  computed: {
    ...mapState("dict", ["dictMap"]),
    ...mapState("car", ["quote","quoteRes"]),
    ...mapState("user", ["info"])
  },
  watch:{
    'pageLayout.basis.issuerCode.config.options':{
      handler(val){
        if(val&&val.length === 1){
          this.$set(this.pageForm.basis, 'issuerCode', val[0].id)
        }
      },
      deep:true
    },
    'pageForm.basis.insuredOrgCode':{
      handler(val){
        if (val === 'YGBX') {
            this.initRiskVal(this.pageRiskForm)
        }
      },
      deep:true
    }
  },
  async created() {
    await this.initData();
    this.initLayout();
  },
  mounted() {
  },
  methods: {
    async initData() {
      // 字典加载
      await getCompanyData(this, this.quote.basis.orgId, this.quote.basis.dealerId)
      await this.$store.dispatch("dict/loadDict");
    },
    initLayout() {
      const layoutData = layout("RISK");
      if (layoutData == null) {
        return null;
      }
      this.config.scriptConfig = layoutData.scriptConfig;
      const layoutAddData = layoutData.addConfig(this,{newEnergyFlag:this.quote.vehicle&&this.quote.vehicle.newEnergyFlag});
      this.initPageData(layoutAddData)
      this.initPageRiskData(layoutAddData.commercial.riskItems)
      this.config.formLayout = layoutAddData;
      this.config.riskLayout = layoutAddData.commercial.riskItems;
      //赋值
      Object.assign(this.pageForm,this.quote)
      this.pageForm.basis.frameNo = this.quote.vehicle.frameNo
      this.pageForm.commercial.riskList&&this.pageForm.commercial.riskList.forEach(riskItem=>{
        if(!this.pageRiskForm[riskItem.riskCode]){
          return false
        }
        Object.assign(this.pageRiskForm[riskItem.riskCode],riskItem)
      })
      if (!this.pageForm.compulsory.checked && !this.pageForm.commercial.checked) {
        this.$set(this.pageForm.compulsory,'checked',true)
        this.$set(this.pageForm.commercial,'checked',true)
      }
      if (!this.pageForm.compulsory.checked) {
        this.config.formLayout['compulsory'].formItems.map(item => {
          item.show = false
        })
      }
      if (!this.pageForm.commercial.checked) {
        this.config.formLayout['commercial'].formItems.map(item => {
          item.show = false
        })
      }
      // 保险公司/出单人员只有一个的时候默认选择
      if (this.dictMap['INS_COMPANY'].length === 1) {
        this.$set(this.pageForm.basis,'insuredOrgCode',this.dictMap['INS_COMPANY'][0].value)
      }
      if (this.dictMap['INS_COMPANY'].length === 1) {
        this.$set(this.pageForm.basis,'insuredOrgCode',this.dictMap['INS_COMPANY'][0].value)
      }
      this.$set(this.pageForm.commercial, 'insuredPlan', '1')
      if (!this.pageForm.commercial.riskList) {
        this.insuredPlanChange('1')
      }
      return layoutData;
    },
    async back() {
      //基本信息验证
      const validateBasis = await this.$refs.basisFormRef.validateForm();
      if(!validateBasis){
        Notify({ type: "warning", message: "请完善基本信息" });
        return false
      }
      if(!(this.pageForm.compulsory.checked || this.pageForm.commercial.checked)){
        Notify({ type: "warning", message: "交强险和商业险至少选择一种" });
        return false
      }
      if(this.pageForm.compulsory.checked){ //交强险验证
        const validateCompulsory = await this.$refs.compulsoryFormRef.validateForm();
        if(!validateCompulsory){
          Notify({ type: "warning", message: "请完善交强险信息" });
          return false
        }
      }
      if(this.pageForm.commercial.checked){ //商业险
        const validateCommercial = await this.$refs.commercialFormRef.validateForm();
        if(!validateCommercial){
          Notify({ type: "warning", message: "请完善商业险基本信息" });
          return false
        }
        const errorList =await this.$refs.riskListRef.validate()
        if(errorList&&errorList.length>0){
          Notify({ type: "warning", message: "请完善商业险险种信息" });
          return false
        }
        const riskForm=this.$refs.riskListRef.riskForm
        const riskList=[]
        for(const code in riskForm){
          const form=riskForm[code]
          if(form&&form.riskCheck){
            riskList.push(form)
          }
        }
        if(riskList.length<1){
          Notify({ type: "warning", message: "商业险至少投保一个险种" });
          return  false
        }
        this.pageForm.commercial.riskList=riskList
      }
      await this.$store.dispatch("car/setQuote", this.pageForm);//保存缓存
      this.$router.go(-1);
    },
    compulsoryCheck (val) {
      if (!val) {
        this.config.formLayout['compulsory'].formItems.map(item => {
          item.show = false
        })
        this.$set(this.pageForm.compulsory,'checked', false)
      } else {
        this.config.formLayout['compulsory'].formItems[0].show = true
        this.config.formLayout['compulsory'].formItems[1].show = true
        this.config.formLayout['compulsory'].formItems[2].show = true
        this.$set(this.pageForm.compulsory,'checked', true)
      }
      this.$store.dispatch("car/setQuote", this.pageForm);
    },
    commercialCheck (val) {
      if (!val) {
        this.config.formLayout['commercial'].formItems.map(item => {
          item.show = false
        })
      } else {
        this.config.formLayout['commercial'].formItems[0].show = true
        this.config.formLayout['commercial'].formItems[1].show = true
      }
    },
    queryCompanyData () {
      let orgId = null
      if (this.quote.basis.orgIdVal) {
        let len = this.quote.basis.orgIdVal.length - 1
        if (len > 0 || len === 0) {
          orgId = this.quote.basis.orgIdVal[len]
        }
      }
      getCompanyData(this, orgId, this.quote.basis.dealerId)
    },
    queryIssuer () {
      let { basis } = this.quote
      let url = null
      let data = {}
      let orgId = null
      const { info } = this.$store.getters
      if (info) {
        if (info.busiType === '1') {
          let len = basis.orgIdVal.length
          orgId = basis.orgIdVal[len - 1]
        }
        if (info.busiType === '2') {
          orgId = basis.orgId
        }
      }
      if (basis.orgId && basis.dealerId) {
        url = '/organization/org/dealer/saleman'
        data = {
          orgId: orgId,
          dealerId: basis.dealerId
        }
      }
      if (basis.orgId && !basis.dealerId) {
        url = '/organization/emp/saleman/work'
        data = {
          orgId: orgId
        }
      }
      request({
        url: url,
        method: 'post',
        data: data
      }).then(res => {
        this.pageLayout.basis.issuerCode.config.options = (!res || res.code) ? [] : res
        const issuerCodeOptions = ((!res || res.code) ? [] : res).filter(item => item.id === this.pageForm.basis.issuerCode)
        if (issuerCodeOptions && !issuerCodeOptions.length) {
          this.pageForm.basis.issuerCode = null
        }
      })
    },
    getChannelData () {
      let len = this.quote.basis.orgIdVal.length
      request({
        url: '/organization/dealer/all',
        method: 'post',
        data: { orgId: this.quote.basis.orgIdVal[len - 1] }
      }).then(res => {
        this.pageLayout.basis.dealerId.config.options = res
      })
    }
  },

};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
@import "@/assets/style/css/cell.scss";
.quote-normal{
  background-color: $background-color;
  padding-top: 1px;
  &_bnt{
    height: 64px;
    &--fixed{
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 99;
      background-color: $white;
      padding: 10px;
      border-top: $background-color solid $border-radius-sm;
    }
  }
}
.quote-normal ::v-deep{
  .van-field__right-icon{
    color: $primary-color;
  }
}
.form {
  background-color: $white;
  margin-top: 6px;
  .form-title {
    color: $text-color;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold-lg;
    line-height: $line-height-lg;
    padding: $padding-xs;
    border-bottom: 1px solid $border-color;
    span.icon {
      border-radius: $border-radius-sm;
      color: $white;
      font-size: $font-size-md;
      padding: 4px;
      margin:0 6px;
    }
    ::v-deep .van-checkbox{
      flex-direction: row-reverse;
      margin-right: 6px;
    }
  }
}

</style>
