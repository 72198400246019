<template>
  <div class="h-cell h-cell--block" >
    <div class="h-cell__title">
      <van-checkbox v-model="riskForm.riskCheck" :disabled="parentRiskCheck" shape="square" icon-size="16px" checked-color="#57ccc2" >
        {{comProps.riskName}}
      </van-checkbox>
    </div>
    <div class="h-cell__form">
        <van-row gutter="20" class="h-flex h-flex-nowrap">
          <span style="white-space:nowrap">实际价值:{{actualValueCal}}</span>
          <input v-model="actualValue" disabled="true" placeholder="实际价值" class="border" />
          <span>元</span>
        </van-row>
        <van-row gutter="20">
          <van-col span="14" class="h-flex h-flex-nowrap">
            <span style="white-space:nowrap">协商价值:</span>
            <input v-model="riskForm.amount" placeholder="协商价格" @change="amountChange" />
            <span>元</span>
          </van-col>
          <van-col span="10" class="h-flex h-flex-nowrap">
            <div class="select">
              <input v-model="amountRate" placeholder="浮动比例" @change="amountRateChange" style="padding-right:25px" />
              <span @click="pageData.show=true"><van-icon name="arrow-down" /></span>
            </div>
            <span>%</span>
          </van-col>
        </van-row>
        <van-row class="h-flex h-flex-nowrap">
          <span style="white-space:nowrap">绝对免赔额:</span>
          <input v-model="riskForm.deductible" placeholder="绝对免赔额" />
          <span>元</span>
        </van-row>
    </div>
      <van-popup v-model="pageData.show" round position="bottom">
        <van-picker
          ref="picker"
          show-toolbar
          title="浮动比例"
          :columns="[-30,-20,-10,0,10,20,30]"
          @confirm="amountRateConfirm"
          @cancel="this.pageData.show=false"
        >
        </van-picker>
      </van-popup>
  </div>
</template>
<script>
import AsyncValidator from 'async-validator'
import { request } from "@/api/service";
import { mapState } from "vuex";
import { Toast } from 'vant';
export default {
  name: 'COMM0A01',
  inheritAttrs: false,
  // eslint-disable-next-line vue/no-unused-components
  components: {},
    inject: {
    pageForm: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    layout: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      pageData:{
        show:false
      },
      riskForm:null,
      actualValue: 0,
      amountRate: 0,
      errors:null
    }
  },
  computed: {
    ...mapState("car", ["quote"]),
    comProps () {
      return Object.assign({
        riskName: null,
        riskCode: null,
        parentCode: null,
        formItems: []
      }, this.layout)
    },
    parentRiskCheck(){
      if(!this.layout.parentCode){
        return false
      }
      if(this.layout.parentCode&&this.form[this.layout.parentCode]&&this.form[this.layout.parentCode].riskCheck){
        return false
      }
      this.cancelCheck()
      return true
    },
    descriptor(){
      const desc={}
      this.layout.formItems.forEach(item=>{
        desc[item.code] = item.config && item.config.rules
      })
      return desc
    },
    actualValueCal(){
      if(!(this.pageForm && this.pageForm.commercial)){
        return null
      }
      const { startTime } = this.pageForm.commercial
      if(!startTime){
        return null
      }
      const {registerDate,vehicleKind,vehicleUsage,vehicleUsageDetail,purchasePrice,seatCount} =this.pageForm.vehicle
      if(!(registerDate&&vehicleKind&&vehicleUsage&&vehicleUsageDetail&&purchasePrice&&seatCount)){
        return null
      }
      const reqActual={
        basis:{
          // insuredOrgCode:this.pageForm.basis && this.pageForm.basis.insuredOrgCode
          orgId: this.pageForm.basis.orgId,
          dealerId: this.pageForm.basis.dealerId
        },
        commercial:{
          startTime:startTime
        },
        vehicle:{
          registerDate:registerDate,
          vehicleKind:vehicleKind,
          vehicleUsage:vehicleUsage,
          vehicleUsageDetail:vehicleUsageDetail,
          purchasePrice:purchasePrice,
          seatCount:seatCount
        }
      }
      this.calculateActualValueIn(reqActual)
      return null
    }
  },
  watch: {
    riskForm:{
      handler (newVal, oldVal) {
        this.formItems().map(formItem => {
          if(formItem.config.val === newVal[formItem.code]){
            return true
          }
          formItem.config.val=newVal[formItem.code]
        })
      },
      deep:true
    },
    actualValue:{
      handler (newVal, oldVal) {
        this.quote.vehicle.actualValue=newVal
        if(this.riskForm.amount || (this.quote.basis.subBusinessNo || this.quote.basis.businessNo || this.quote.basis.insBusinessNo)){
          this.amountRateCal(this.amountRate||0)
        }
      },
    }
  },
  created () {
    this.initForm()
  },
  methods: {
    initForm () {
      this.riskForm=this.form[this.comProps.riskCode]
      this.riskForm.riskName = this.comProps.riskName
      this.riskForm.riskCode = this.comProps.riskCode
      this.$set(this.riskForm, 'riskCheck', this.riskForm['riskCheck'] || false)
      this.comProps.formItems.forEach(formItem => {
        this.$set(this.riskForm, formItem.code, this.riskForm[formItem.code] || formItem.config.val)
      })
    },
    formItems () {
      return this.layout.formItems.filter(item => item.show)
    },
    clearValidate () {
      this.errors=null
    },
    cancelCheck(){
      this.riskForm.riskCheck = false
    },
    async validate () {
      return new Promise((resolve) => {
        new AsyncValidator(this.descriptor).validate(this.riskForm, { firstFields: true }, (errors, invalidFields) => {
          this.errors=errors
          if(errors){
            resolve(false)
          }else{
            resolve(true)
          }
        });
      })
    },
    amountRateChange(){
      this.amountRateCal(Number(this.amountRate))
    },
    amountChange(){
      if(!(this.riskForm.amount&&this.actualValue)){
          return false
      }
      if (Math.abs(((this.riskForm.amount - this.actualValue) / this.actualValue) * 100) > 30) {
        Toast('协商价值浮动不应超过实际价值的上下30%')
      }
    },
    amountRateConfirm(e){
      this.amountRateCal(e)
    },
    amountRateCal(val){
      if (Math.abs(+val) > 30) {
        Toast('协商价值浮动不应超过实际价值的上下30%')
        this.amountRate = 0
        return false
      }
      this.riskForm.amount = ((100 - val) * this.actualValue * 0.01).toFixed(2)
      this.amountRate = val
      this.pageData.show = false
    },
    calculateActualValueIn(reqParam){
      request({
        url: "/afis-api-manage/invoke/core/auto/calculateActualValueIn",
        method: "post",
        data: reqParam,
      }).then((resParam) => {
        this.actualValue = resParam.actualValue
        this.pageForm.vehicle.currentValue = this.actualValue
        this.pageForm.vehicle.actualValue = this.actualValue
        this.$set(this.pageForm.vehicle, 'currentValue', this.actualValue)
        if (this.$parent.riskForm.COMM1M01) {
            this.$parent.riskForm.COMM1M01.amount = this.actualValue
        }
        if (this.$parent.riskForm.COMM0M01) {
            this.$parent.riskForm.COMM0M01.amount = this.actualValue
        }
      }).catch((error) => {
        this.actualValue = 0
      });
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
@import "@/assets/style/css/cell.scss";
.h-cell {
  padding: 10px;
}
.h-cell__form{
  padding: 10px 10px;
}
.van-row{
  padding: 5px 0;
}
input{
  display: block;
  box-sizing: border-box;
  width: 100%;
  min-width: 0;
  margin: 0;
  padding: 0;
  color: #323233;
  line-height: inherit;
  text-align: left;
  background-color: transparent;
  border: 1px solid #dcdfe6;
  border-width: 0 0 1px 0;
  padding: 0 5px;
  resize: none;
}
input.border{
  border:0
}
.select{
  position: relative;
  span{
    position: absolute;
    top: 0;
    right: 0;
    padding: 0px 5px;
  }

}
</style>
