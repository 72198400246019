<script>
export default {
  name: 'RiskRender',
  functional: true,
  props: {
    componentRef: String,
    control: String,
    code:String,
    form: Object,
    layout: Object,
    on: Object
  },
  render: (h, ctx) => {
    const { componentRef,code, control, form, layout, on } = ctx.props
    return h(control, { ref: componentRef,key:code, props: { form,layout }, on })
  }
}
</script>
