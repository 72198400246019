import util from "../util";
import { request } from "@/api/service";
import { mergeWith, keys, clone,cloneDeep } from "lodash";
import dayjs from "dayjs";
import Util from '@/utils/common'
import MotorRisk from "../../layout/risk/motor";
import EnergyRisk from "../../layout/risk/energy";
const basis = {
  watchVehicleSendhandCarFlag: function(val) {
    this.pageForm.basis.sendhandCarFlag = this.pageForm.vehicle.sendhandCarFlag;
  },
  watchVehicleMileage: function(val) {
    this.pageForm.basis.mileage = this.pageForm.vehicle.mileage;
  },
  watchVehiclePlateNo: function(val) {
    this.pageForm.basis.plateNo = this.pageForm.vehicle.plateNo || "新车未上牌";
  },
  watchOwnerName: function(val) {
    this.pageForm.basis.ownerName = this.pageForm.owner.name;
  },
  watchDealerId: function(val) {
    let copyObj = cloneDeep(this.pageForm)
    if (!!val) {
      let filters = this.pageLayout.basis.dealerId.config.options.filter(item => item.id === val)
      if (filters.length) {
        copyObj.basis.dealerName = filters[0].label
      }
    }
    this.$store.dispatch("car/setQuote", copyObj);
    this.queryCompanyData()
    this.queryIssuer()
  },
  watchInsuredOrgCode: function(val) {
    util.offShow(this.pageLayout.basis.quoteType);
    util.offShow(this.pageLayout.basis.bociVipFlag);
    util.offShow(this.pageLayout.basis.bociCustomerSource);
    switch (val) {
      // 中国平安
      case "PAIC":
        util.onShow(this.pageLayout.basis.quoteType);
        break
      // 中银财险
      case "BOCI":
        util.onShow(this.pageLayout.basis.bociVipFlag);
        util.onShow(this.pageLayout.basis.bociCustomerSource);
        break
    }
  },
};
const vehicleTax = {
  watchTaxType: function(val) {
    util.offShow(this.pageLayout.compulsory.taxAgency); //税务机关名称
    util.offShow(this.pageLayout.compulsory.taxCertificateNo); //凭证号名称
    util.offShow(this.pageLayout.compulsory.taxDocumentDate); //开具凭证日期
    util.offShow(this.pageLayout.compulsory.taxAbateReason); //原因
    util.offShow(this.pageLayout.compulsory.taxAbateType); //减税方案
    util.offShow(this.pageLayout.compulsory.taxAbateProportion); //减税比例
    util.offShow(this.pageLayout.compulsory.taxAbateAmount); //减税金额
    if (val === "4") {
      //减税
      util.onShow(this.pageLayout.compulsory.taxAgency);
      util.onShow(this.pageLayout.compulsory.taxCertificateNo);
      util.onShow(this.pageLayout.compulsory.taxAbateReason);
      util.onShow(this.pageLayout.compulsory.taxAbateType);
      this.pageLayout.compulsory.taxAbateReason.config.label = '减税原因'
      this.pageLayout.compulsory.taxCertificateNo.config.label = '减免凭证号'
      if (this.pageForm.compulsory.taxAbateType === "P") {
        //比例
        util.onShow(this.pageLayout.compulsory.taxAbateProportion); //减税比例
      } else if (this.pageForm.compulsory.taxAbateType === "A") {
        //金额
        util.onShow(this.pageLayout.compulsory.taxAbateAmount); //减税金额
      }
    } else if (val === "2") {
      //免税
      util.onShow(this.pageLayout.compulsory.taxAgency);
      util.onShow(this.pageLayout.compulsory.taxCertificateNo);
      util.onShow(this.pageLayout.compulsory.taxAbateReason);
      this.pageLayout.compulsory.taxAbateReason.config.label = '免税原因'
    } else if (val === "1") {
      //完税
      util.onShow(this.pageLayout.compulsory.taxAgency);
      util.onShow(this.pageLayout.compulsory.taxCertificateNo);
      util.onShow(this.pageLayout.compulsory.taxDocumentDate);
      this.pageLayout.compulsory.taxCertificateNo.config.label = '完税凭证号'
    }
  },
  watchTaxAbateType: function(val) {
    if (val === "P") {
      //比例
      util.onShow(this.pageLayout.compulsory.taxAbateProportion); //减税比例
      util.offShow(this.pageLayout.compulsory.taxAbateAmount); //减税金额
    } else if (val === "A") {
      //金额
      util.onShow(this.pageLayout.compulsory.taxAbateAmount); //减税金额
      util.offShow(this.pageLayout.compulsory.taxAbateProportion); //减税比例
    } else {
      util.offShow(this.pageLayout.compulsory.taxAbateProportion); //减税比例
      util.offShow(this.pageLayout.compulsory.taxAbateAmount); //减税金额
    }
  },
};
const risk = {
  watchVehicleSeatCount: function(val) {
    const seatCount = Number(val) || 0;
    if (seatCount < 1) {
      return false;
    }
    if (this.pageForm.vehicle.newEnergyFlag === "1") {
      //新能源
      this.pageRiskLayout.COMM1D01.quantity.config.val = seatCount - 1;
      this.pageRiskLayout.COMM1D03.quantity.config.val = seatCount - 1;
      if (this.pageRiskForm.COMM1D01) {
        this.pageRiskForm.COMM1D01.quantity = seatCount - 1;
      }
      if (this.pageRiskForm.COMM1D03) {
        this.pageRiskForm.COMM1D03.quantity = seatCount - 1;
      }
      return false;
    }
    this.pageRiskLayout.COMM0D01.quantity.config.val = seatCount - 1;
    this.pageRiskLayout.COMM0D03.quantity.config.val = seatCount - 1;
    if (this.pageRiskForm.COMM0D01) {
      this.pageRiskForm.COMM0D01.quantity = seatCount - 1;
    }
    if (this.pageRiskForm.COMM0D03) {
      this.pageRiskForm.COMM0D03.quantity = seatCount - 1;
    }
  },
  watchCompulsoryStartTime: function(val) {
    const endTimeData = dayjs(val).add(1, "year");
    this.pageForm.compulsory.endTime = endTimeData.valueOf();
  },
  watchCommercialStartTime: function(val) {
    const endTimeData = dayjs(val).add(1, "year");
    this.pageForm.commercial.endTime = endTimeData.valueOf();
  },
  watchRisk:function(val){
    if (val === "1") {
      this.config.riskLayout = EnergyRisk(this, {});
    } else {
      this.config.riskLayout = MotorRisk(this, {});
    }
  },
  watchCause:function(val){
    const causeLayout=this.config.riskLayout.filter((item)=>{return item.riskCode==='COMM1G01'||item.riskCode==='COMM0G01'})
    const insuredOrgCode=this.pageForm.basis&&this.pageForm.basis.insuredOrgCode
    if(["PICC","GPIC","BOCI"].indexOf(insuredOrgCode)>-1){
      //this.pageRiskLayout.COMM1G01.control="Cause"
      causeLayout.forEach(item=>{
        item.control="Cause"
      })
    }else{
      //this.pageRiskLayout.COMM1G01.control="Form"
      causeLayout.forEach(item=>{
        item.control="Form"
      })
    }
  },
  watchChargePile:function(val){
    if(!(this.pageRiskForm.COMM1M02||this.pageRiskForm.COMM1M03)){
      return false
    }
    const causeLayout=this.config.riskLayout.filter((item)=>{return item.riskCode==='COMM1M03'})
    if(!(causeLayout&&causeLayout.length>0)){
      return false
    }
    if(this.pageRiskForm.COMM1M02.riskCheck&&this.pageRiskForm.COMM1M03.riskCheck){
      causeLayout.forEach(item=>{
        item.control="Select"
        item.formItems[0].control = "HSelect"
      })
    }else{
      causeLayout.forEach(item=>{
        item.control="ChargePile"
        item.formItems[0].control = "HSelectDown"
      })
    }
  }
};
export default {
  data() {
    return {
      pageRiskForm: {},
      pageRiskLayout: {},
    };
  },
  computed: {},
  watch: {
    "pageForm.basis.dealerId": function(val) {
      basis.watchDealerId.call(this, val);
    },
    "pageForm.basis.insuredOrgCode": function(val) {
      basis.watchInsuredOrgCode.call(this, val);
      risk.watchCause.call(this, val);
    },
    "pageForm.vehicle.plateNo": function(val) {
      basis.watchVehiclePlateNo.call(this, val);
    },
    "pageForm.vehicle.sendhandCarFlag": function(val) {
      basis.watchVehicleSendhandCarFlag.call(this, val);
    },
    "pageForm.vehicle.mileage": function(val) {
      basis.watchVehicleMileage.call(this, val);
    },
    "pageForm.owner.name": function(val) {
      basis.watchOwnerName.call(this, val);
    },
    "pageForm.vehicle.seatCount": function(val) {
      risk.watchVehicleSeatCount.call(this, val);
    },
    "pageForm.vehicle.newEnergyFlag": function(val) {
      risk.watchRisk.call(this, val);
      risk.watchCause.call(this, val);
      risk.watchChargePile.call(this,val)
    },
    "pageForm.compulsory.startTime": function(val) {
      risk.watchCompulsoryStartTime.call(this, val);
    },
    "pageForm.compulsory.taxType": function(val) {
      vehicleTax.watchTaxType.call(this, val);
    },
    "pageForm.compulsory.taxAbateType": function(val) {
      vehicleTax.watchTaxAbateType.call(this, val);
    },
    "pageForm.commercial.startTime": function(val) {
      risk.watchCommercialStartTime.call(this, val);
    },
    "pageRiskForm.COMM1M02.riskCheck":function(val){
      risk.watchChargePile.call(this,val)
    },
    "pageRiskForm.COMM1M03.riskCheck":function(val){
      risk.watchChargePile.call(this,val)
    }
  },
  methods: {
    initPageRiskData(layoutRiskData) {
      //初始化表单和布局
      for (const key in layoutRiskData) {
        const formItem = layoutRiskData[key];
        this.$set(
          this.pageRiskForm,
          formItem.riskCode,
          formItem.form || {
            riskCheck: false,
          }
        );
        this.$set(this.pageRiskLayout, formItem.riskCode, {control:formItem.control});
        const formItems = formItem.formItems;
        formItems &&
          formItems.forEach((item) => {
            this.$set(
              this.pageRiskForm[formItem.riskCode],
              item.code,
              item.config && item.config.val
            );
            this.$set(this.pageRiskLayout[formItem.riskCode], item.code, item);
          });
      }
      // 判断经销商端和中介端出单机构的展示
      const { info } = this.$store.getters
      let url = null
      let orgId = null
      let dealerId = null
      let copyObj = clone(this.quote)
      if (info.busiType === '2') {
        this.pageLayout.basis.orgName.config.val = info.issueShortName
        util.onShow(this.pageLayout.basis.orgName);
        util.offShow(this.pageLayout.basis.orgIdVal);
        util.onShow(this.pageLayout.basis.dealerName);
        util.offShow(this.pageLayout.basis.dealerId);
        this.pageLayout.basis.dealerName.config.val = info.orgName
        url = '/organization/org/dealer/saleman'
        orgId = info.issueOrgId
        dealerId = info.orgId
        copyObj.basis.dealerId = info.orgId
      } else {
        util.onShow(this.pageLayout.basis.orgIdVal);
        util.offShow(this.pageLayout.basis.orgName);
        util.onShow(this.pageLayout.basis.dealerId);
        util.offShow(this.pageLayout.basis.dealerName);
        if (this.$store.getters.quote.basis.orgIdVal && this.$store.getters.quote.basis.orgIdVal.length) {
          let len = this.$store.getters.quote.basis.orgIdVal.length
          orgId = this.$store.getters.quote.basis.orgIdVal[len - 1]
        } else {
          orgId = info.issueOrgId
          copyObj.basis.orgName = info.issueShortName
        }
        this.$store.dispatch("car/setQuote", copyObj);
        // 查询业务渠道
        // debugger
        request({
          url: '/organization/dealer/all',
          method: 'post',
          data: { orgId }
        }).then(res => {
          this.pageLayout.basis.dealerId.config.options = res
        })
        if (orgId && dealerId) {
          url = '/organization/org/dealer/saleman'
        }
        if (orgId && !dealerId) {
          url = '/organization/emp/saleman/work'
        }
      }
      // 查询出单人员
      let data = {}
      if (url == '/organization/org/dealer/saleman') {
        data = {
          orgId: orgId,
          dealerId: dealerId
        }
      } else {
        data = {
          orgId: orgId
        }
      }
      // 查询出单人员
      request({
        url: url,
        method: "post",
        data: data
      }).then(res => {
        this.pageLayout.basis.issuerCode.config.options = res
      })
      // 查询出单机构
      request({
        url: '/organization/org/tree',
        method: "post",
        data: data
      }).then(res => {
        let arr = Util.buildtree(res);
        // children为空时去除children属性
        function getData (data) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].children.length === 0) {
              delete data[i].children
            } else {
              getData(data[i].children)
            }
          }
          return data
        }
        arr = getData(arr)
        this.pageLayout.basis.orgIdVal.config.options = arr
      })
    },
    insuredPlanChange(value) {
      switch (value) {
        case "1": //常用方案
          for (const riskCode in this.pageRiskForm) {
            const riskObj = this.pageRiskForm[riskCode];
            if (
              riskCode === "COMM0A01" ||
              riskCode === "COMM0B01" ||
              riskCode === "COMM1A01" ||
              riskCode === "COMM1B01"
            ) {
              riskObj.riskCheck = true;
              if (riskCode === "COMM0B01" || riskCode === "COMM1B01") {
                riskObj.amount = 1000000;
              }
              continue;
            }
            riskObj.riskCheck = false;
          }
          break;
        case "2":
          for (const riskCode in this.pageRiskForm) {
            const riskObj = this.pageRiskForm[riskCode];
            if (
              riskCode === "COMM0A01" ||
              riskCode === "COMM0B01" ||
              riskCode === "COMM0C01" ||
              riskCode === "COMM0D01" ||
              riskCode === "COMM1A01" ||
              riskCode === "COMM1B01" ||
              riskCode === "COMM1C01" ||
              riskCode === "COMM1D01"
            ) {
              riskObj.riskCheck = true;
              if (riskCode === "COMM0B01" || riskCode === "COMM1B01") {
                riskObj.amount = 1000000;
              }
              continue;
            }
            riskObj.riskCheck = false;
          }
          break;
        case "3":
          for (const riskCode in this.pageRiskForm) {
            const riskObj = this.pageRiskForm[riskCode];
            riskObj.riskCheck = false;
          }
          break;
        case "4":
          for (const riskCode in this.pageRiskForm) {
            const riskObj = this.pageRiskForm[riskCode];
            riskObj.riskCheck = false;
          }
          this.queryCarOwnerVehicle();
          break;
      }
      // this.pageForm.commercial.insuredPlan = value
      const riskList=[]
      for (const riskCode in this.pageRiskForm) {
        const riskObj = this.pageRiskForm[riskCode];
        if(riskObj.riskCheck){
          riskObj.riskCode = riskCode
          riskList.push(riskObj)
        }
      }
      this.pageForm.commercial.riskList=riskList
      //this.$set(this.pageForm.commercial, 'insuredPlan', value)
      this.$store.dispatch("car/setQuote", this.pageForm)
    },
    async queryCarOwnerVehicle() {
      const res = await request({
        url: "/afis-auto-web/auto/policy/queryRecent",
        method: "post",
        data: {
          frameNo: this.pageForm.vehicle.frameNo,
          plateNo: this.pageForm.vehicle.plateNo,
        },
      });
      if (res.commercial && res.commercial.riskList) {
        res.commercial.riskList.forEach((item) => {
          this.pageRiskForm[item.riskCode].riskCheck = true;
        });
      }
    },
  },
};
