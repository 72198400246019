<template>
  <div class="h-cell h-cell--block" >
    <div class="h-cell__title">
      <van-checkbox v-model="riskForm.riskCheck" :disabled="parentRiskCheck" shape="square" icon-size="16px" checked-color="#57ccc2" >
        <span :class="{'disabled-cla': !riskForm.riskCheck}">{{comProps.riskName}}</span>
      </van-checkbox>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Empty',
  inheritAttrs: false,
  components: {},
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    layout: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      riskForm:null
    }
  },
  computed: {
    comProps () {
      return Object.assign({
        riskName: null,
        riskCode: null,
        parentCode: null,
        formItems: []
      }, this.layout)
    },
    parentRiskCheck(){
      if(!this.comProps.parentCode){
        return false
      }
      if(this.comProps.parentCode&&this.form[this.comProps.parentCode]&&this.form[this.comProps.parentCode].riskCheck){
        return false
      }
      this.cancelCheck()
      return true
    }
  },
  created () {
    this.initForm()
  },
  methods: {
    initForm () {
      this.riskForm=this.form[this.comProps.riskCode]
      this.riskForm.riskName = this.comProps.riskName
      this.riskForm.riskCode = this.comProps.riskCode
      this.$set(this.riskForm, 'riskCheck', this.riskForm['riskCheck'] || false)
      this.comProps.formItems.forEach(formItem => {
        this.$set(this.riskForm, formItem.code, this.riskForm[formItem.code] || formItem.config.val || null)
      })
    },
    formItems () {
      return this.layout.formItems.filter(item => item.show)
    },
    clearValidate () {
    },
    cancelCheck(){
      this.riskForm.riskCheck = false
    },
    validate () {
      return true
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
@import "@/assets/style/css/cell.scss";
.h-cell {
  padding: 10px;
}
.disabled-cla {
  color: #c8c9cc;
}
</style>