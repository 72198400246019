<template>
  <div class="h-cell h-cell--block table" >
    <div class="h-cell__title">
      <van-checkbox v-model="riskForm.riskCheck" :disabled="parentRiskCheck" shape="square" icon-size="16px" checked-color="#57ccc2" >
        <div class="h-flex h-row-between h-col-center">
          <div :class="{'disabled-cla': !riskForm.riskCheck}">{{comProps.riskName}}</div>
          <div class="h-flex" @click.stop="showAdd">
            <van-button :disabled="!riskForm.riskCheck" plain round block type="primary" size="mini">条款项录入</van-button>
          </div>
        </div>
      </van-checkbox>
    </div>
    <div class="tableForm" id="tablePopup">
      <van-popup v-model="pageData.popupShow" round closeable position="bottom" teleport="#tablePopup" >
        <div class="title">
          条款项
        </div>
        <div class="content">
          <div class="h-p-5">
            <div v-for="item in comProps.serviceItems" :key="item.code" class="h-flex h-row-between h-p-5">
              <div>{{item.label}}</div>
              <div class="h-flex h-flex-nowrap">
                <van-stepper 
                  v-if="formMap[item.code]&&formMap[item.code]>0" 
                  v-model="formMap[item.code]" 
                  theme="round" 
                  min="0" 
                  default-value="0" 
                  integer 
                  button-size="22"
                  @change="(val)=>changeOpt(val,item)"/>
                <div v-else style="height:22px">
                  <van-icon name="add" size="25" color="#ee0a24" @click="addOpt(item)" />
                </div>
              </div>
            </div>
          </div>
          <div class="btn">
            <div class="fixed h-flex h-row-around h-col-center">
              <van-button round plain type="primary" @click="closeOpt">取消</van-button>
              <van-button round type="primary" @click="submitOpt">确认</van-button>
            </div>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
export default {
  name: 'Cause',
  inheritAttrs: false,
  components: {},
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    layout: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      pageData:{
        popupShow:false,
        popupViewShow:false
      },
      riskForm:null,
      formObj:{
        config:{},
        item:{},
        viewItem:{},
      },
      formList:[]
    }
  },
  computed: {
    comProps () {
      return Object.assign({
        riskName: null,
        riskCode: null,
        parentCode: null,
        serviceItems:[],
        formItems: []
      }, this.layout)
    },
    parentRiskCheck(){
      if(!this.comProps.parentCode){
        return false
      }
      if(this.comProps.parentCode&&this.form[this.comProps.parentCode]&&this.form[this.comProps.parentCode].riskCheck){
        return false
      }
      this.cancelCheck()
      return true
    },
    formMap(){
      const map={}
      this.formList.forEach(item => {
        map[item.serviceCode]=item.quantity
      })
      return map
    }
  },
  created () {
    this.initForm()
  },
  methods: {
    initForm () {
      this.riskForm=this.form[this.comProps.riskCode]
      this.riskForm.riskName = this.comProps.riskName
      this.riskForm.riskCode = this.comProps.riskCode
      this.$set(this.riskForm, 'riskCheck', this.riskForm['riskCheck'] || false)
      if(this.riskForm.serviceList&&this.riskForm.serviceList.length>0){
        this.formList=this.riskForm.serviceList
      }else{
        const serviceList=[]
        this.comProps.serviceItems.forEach(item=>{
          if(item.default){
            serviceList.push({
              quantity: item.default,
              serviceCode: item.code,
              serviceDepict: item.label,
            })
          }
        })
        this.formList=serviceList
        this.riskForm.serviceList=serviceList
      }
    },
    clearValidate () {
    },
    cancelCheck(){
      this.riskForm.riskCheck = false
    },
    validate () {
      if(this.riskForm.serviceList&&this.riskForm.serviceList.length>0){
        return true
      }
      return false
    },
    showAdd(){
      if(!this.riskForm.riskCheck){
        return false
      }
      this.pageData.popupShow = true
    },
    closeOpt(){
      this.pageData.popupShow = false
    },
    addOpt(item){
      this.formList.push({
        quantity: 1,
        serviceCode: item.code,
        serviceDepict: item.label,
      })
    },
    changeOpt(val,obj){
      this.formList.forEach((item,index)=>{
        if(item.serviceCode!==obj.code){
          return false
        }
        if(val<=0){
          this.formList.splice(index, 1);
        }else{
          item.quantity=val
        }
      })
    },
    submitOpt(){
      if(!(this.formList&&this.formList.length>0)){
        this.$notify("至少选择一项")
        return false
      }
      this.riskForm.serviceList=this.formList
      this.pageData.popupShow = false
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
@import "@/assets/style/css/cell.scss";
.h-cell {
  padding: 10px;
}
.disabled-cla {
  color: #c8c9cc;
}
::v-deep .van-checkbox__label{
  width: 100%;
}
.tableForm{
  .van-popup{
    height: 70vh;
  }
  .title{
    height: 50px;
    line-height: 50px;
    text-align: center;
    white-space: nowrap;
    font-size: $font-size-xl;
    font-weight: $font-weight-bold-lg;
    border-bottom: 1px dashed $primary-color;
  }
  .content{
    position: relative;
    height: calc(100% - 50px - 55px);
    overflow: scroll;
    ::v-deep .van-stepper{
      display: flex;
    }
    .btn{
      position: relative;
      .van-button{
        width: 100px;
      }
      .fixed{
        height: 45px;
        position: fixed; 
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        padding: 5px 0;
        // padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
        // padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
      }
    }
  }
}
</style>