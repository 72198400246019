import { request } from "@/api/service";
import store from "@/store";
import { mergeWith, cloneDeep, isArray } from "lodash";
import companyData from '@/views/miniprogram/quote/common/mixin/company.js'
import { Notify } from "vant";
export default function getCompanyData (_this, orgId, businessChannelId) {
  const { info } = _this.$store.getters
  let param = {}
  if (info.busiType === '2') {
    param.orgId = info.issueOrgId
    param.businessChannelId = info.orgId
    param.commonApiConfigType = '1'
  }
  if (info.busiType === '1') {
    param.orgId = orgId || info.issueOrgId
    param.businessChannelId = businessChannelId
    param.commonApiConfigType = '1'
  }
  request({
      url: '/afis-auth/auth/user/queryCurrentIssueCompanyCodeList',
      method: 'post',
      data: param
    }).then(dictRes => {
    if ((!dictRes || isArray(dictRes) && !dictRes.length) && param.businessChannelId) {
        Notify({ type: "warning", message: "当前机构暂不存在车险投保权限，可联系客服申请权限！" });
        _this.pageLayout.basis.insuredOrgCode.config.options = []
        return
    }
    let setCompany = []
    companyData.map(item => {
        if (isArray(dictRes) && dictRes.includes(item.value)) {
            setCompany.push(item)
        }
    })
    _this.pageLayout.basis.insuredOrgCode.config.options = setCompany
    if (setCompany.length === 1 && ! _this.pageForm.basis.insuredOrgCode) {
        _this.pageForm.basis.insuredOrgCode = setCompany[0].value
    }
  }).catch(e => {
    _this.pageLayout.basis.insuredOrgCode.config.options = []
  })   
}