<template>
  <div class="risk">
    <div class="risk_select">
      <van-radio-group v-model="pageForm.commercial.insuredPlan" direction="horizontal" icon-size="16px" @change="insuredPlanChange">
        <van-radio name="1" shape="square" checked-color="#57ccc2">常用方案</van-radio>
        <van-radio name="2" shape="square" checked-color="#57ccc2">全险</van-radio>
        <van-radio name="3" shape="square" checked-color="#57ccc2">自定义</van-radio>
        <van-radio name="4" shape="square" checked-color="#57ccc2">同上次报价单</van-radio>
      </van-radio-group>
    </div>
    <div class="h-p-l-10 h-primary-color">
      {{pageForm.vehicle.newEnergyFlag==="0"?"普通车商业险别":"新能源车商业险别"}}
    </div>
    <div class="main-risk">
      <van-row class="main-risk_title">
        <van-col span="12">险别</van-col>
        <van-col span="12" class="h-text-right">保额</van-col>
      </van-row>
      <div class="main-risk_content">
        <div class="h-group">
          <template v-for="riskItem in mainRiskLayout">
            <RiskRender
              :key="riskItem.riskCode"
              :code="riskItem.riskCode"
              :component-ref="riskItem.riskCode + 'Ref'"
              :control="riskItem.control"
              :layout="riskItem"
              :form="riskForm"
              :on="riskItem.on"
            />
          </template>
        </div>
      </div>
    </div>
    <div class="extra-risk">
      <div class="extra-risk_title">
        <span></span>常用附加险
      </div>
      <div class="extra-risk_content">
        <div class="h-group">
          <template v-for="riskItem in extraRiskLayout">
            <RiskRender
              :key="riskItem.riskCode"
              :code="riskItem.riskCode"
              :component-ref="riskItem.riskCode + 'Ref'"
              :control="riskItem.control"
              :layout="riskItem"
              :form="riskForm"
              :on="riskItem.on"
            />
          </template>
        </div>
      </div>
    </div>
    <div class="service-risk">
      <div class="service-risk_title">
        <span></span>附加机动车增值服务特约条款
      </div>
      <div class="service-risk_content">
        <div class="h-group">
          <template v-for="riskItem in serviceRiskLayout">
            <RiskRender
              :key="riskItem.riskCode"
              :code="riskItem.riskCode"
              :component-ref="riskItem.riskCode + 'Ref'"
              :control="riskItem.control"
              :layout="riskItem"
              :form="riskForm"
              :on="riskItem.on"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import RiskRender from './RiskRender'
const context = require.context('./risk/', false, /.vue$/)
context.keys().forEach(key => {
  const component = context(key).default
  Vue.component(component.name, component)
})
export default {
  name: 'RiskList',
  inheritAttrs: false,
  components: { RiskRender },
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {
          pageData: {}
        }
      }
    }
  },
  props: {
    layout: {
      type: Array,
      default: () => []
    },
    riskForm:{
      type: Object,
      default: () => {}
    },
    pageForm:{
      type: Object,
      default: () => {}
    }
  },
  watch: {
    layout(){
      this.initForm()
    }
  },
  data () {
    return {
    }
  },
  computed: {
    mainRiskLayout(){
      return this.layout.filter(item => item.type==='main')
    },
    extraRiskLayout(){
      return this.layout.filter(item => item.type==='extra')
    },
    serviceRiskLayout(){
      return this.layout.filter(item => item.type==='service')
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      // 初始化 model
      this.initForm()
    },
    initForm () {
      this.layout.forEach(riskItem=>{
        if(!this.riskForm[riskItem.riskCode]){
          this.riskForm[riskItem.riskCode] = {}
        }
      })
    },
    clearValidate () {
    },
    async validate () {
      const errorList=[]
      for(const i in this.layout){
        const riskItem=this.layout[i]
        const code=riskItem.riskCode
        if(!(this.riskForm[code]&&this.riskForm[code].riskCheck)){
          continue;
        }
        if (this.$refs[code+'Ref'] && this.$refs[code+'Ref'].validate) {
          const validateFlag=await this.$refs[code+'Ref'].validate()
          if(!validateFlag){
            errorList.push({
              code:riskItem.riskCode,
              name:riskItem.riskName,
              errors:this.$refs[code+'Ref']&&this.$refs[code+'Ref'].errors
            })
          }
        }
      }
      return errorList
    },
    insuredPlanChange(value){
      this.pageContext.insuredPlanChange(value)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
.risk_select ::v-deep{
  padding: 10px;
  background-image: linear-gradient(to bottom, #90dcd5, #fff);
  .van-radio__label{
    font-size: 12px;
    margin-left: 2px;
  }
  .van-radio__icon--checked + span.van-radio__label{
    font-weight: $font-weight-bold-lg;
  }
}
.main-risk{
  border-bottom:1px dashed $primary-color;
  padding-bottom: 10px;
  &_title{
    font-weight: $font-weight-bold-lg;
    padding: 10px;
  }
}
.extra-risk{
  border-bottom:1px dashed $primary-color;
  padding-bottom: 10px;
  &_title{
    font-weight: $font-weight-bold-lg;
    padding: 10px;
    span{
      display: inline-block;
      height: 10px;
      width: 10px;
      background-color: #1989FA;
      margin-right: 10px;
      border-radius: 0 5px 5px 0;
    }
  }
}
.service-risk{
  padding-bottom: 10px;
  &_title{
    font-weight: $font-weight-bold-lg;
    padding: 10px;
    span{
      display: inline-block;
      height: 10px;
      width: 10px;
      background-color: #ed6a0c;
      margin-right: 10px;
      border-radius: 0 5px 5px 0;
    }
  }
}
</style>
