<template>
  <div>
    <div class="h-flex h-row-right h-flex-nowrap">
      <div class="risk-select h-flex" :class="{'disabled-cla': !form.riskCheck}" @click="openPopup">
          <div class="risk-select_value" :class="{'risk-select--text':currentText?false:true}">{{ currentText || elProps.placeholder }}</div>
          <div class="h-p-l-5"><van-icon :name="pageData.show?'arrow-up':'arrow-down'" color="#969799" /></div>
      </div>
      <div class="unit" v-if="config.unit">{{ config.unit }}</div>
      <div class="append" v-if="config.append">{{ config.append }}</div>
    </div>
    <div>
      <van-popup v-model="pageData.show" round position="bottom">
        <van-picker
          ref="picker"
          show-toolbar
          :title="elProps.label"
          :columns="options"
          :value-key="elProps.labelKey"
          @confirm="handleConfirm"
          @cancel="handleCancel"
        >
        </van-picker>
      </van-popup>
    </div>
  </div>
</template>
<script>
import { findIndex } from 'lodash'
export default {
  name: 'HSelectDown',
  inheritAttrs: false,
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {
          pageData: {}
        }
      }
    }
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    },
    form:{
      type: Object,
      default: () => {}
    }
  },
  watch: {
    'config.val': {
      handler (newVal, oldVal) {
        this.val = newVal
      }
    },
    'config.options': {
      handler (newVal, oldVal) {
        this.options = newVal
      }
    }
  },
  data () {
    return {
      pageData:{
        show:false
      },
      val: null,
      options: []
    }
  },
  computed: {
    elProps () {
      return Object.assign({
        val: null,
        show: true,
        readonly: true,
        disabled: false,
        options: [],
        valueKey:'value',
        labelKey:'label',
        clearable: true,
        blurEvent: null,
        changeEvent: null,
        placeholder: '请选择'
      }, this.config)
    },
    currentText:{
      get(){
        if(!(this.options&&this.options.length>0)){
          return null
        }
        const textOption=this.options.filter(item=>{return item[this.elProps.valueKey] === this.val})
        if(textOption && textOption.length>0){
          return textOption[0][this.elProps.labelKey]
        }else{
          return null
        }
      },
      set(){

      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.val = this.config.val || (this.elProps.code&&this.form[this.elProps.code])
      this.$set(this, 'options', this.elProps.options || [])
    },
    handleEvent (eventType) {
      const event = this.config[eventType + 'Event']
      if (event) {
        if (typeof event=== 'string' || event instanceof String) {
          // eslint-disable-next-line no-new-func
          new Function(`return function(ctx) { ${event} }`)()(this)
        } else if (event instanceof Function) {
          event(this)
        }
      }
    },
    openPopup(){
      if(!(this.form&&this.form.riskCheck)){
        return false
      }
      this.pageData.show=true
      const index=findIndex(this.options,[this.elProps.valueKey, this.val])
      this.$nextTick(()=>{
        this.$refs.picker.setColumnIndex(0,index)
      })
    },
    handleConfirm(data){
      this.pageData.show=false
      if(!data){
        return false
      }
      this.val=data[this.elProps.valueKey]
      //this.currentText=data[this.elProps.labelKey]
      this.config.val = this.val
      this.$emit('input', this.val)
      this.handleEvent('confirm')
    },
    handleCancel(){
      this.pageData.show=false
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
.risk-select{
  font-weight: $font-weight-bold;
  color: $text-color;
  border-bottom: 1px solid #dcdfe6;
  padding: 0 2px;
  &--text{
    color: $primary-color;
  }
  .unit,.append{
    padding: 0 2px;
  }
  .unit{
    color: $primary-color;
  }
}
.risk-select--text{
  color: #c8c9cc
}
.disabled-cla {
  .risk-select_value {
    color: #c8c9cc !important;
  }
}
</style>
