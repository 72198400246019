<template>
  <div class="h-cell h-cell--block" >
    <div class="h-cell__title">
      <van-checkbox v-model="riskForm.riskCheck" :disabled="parentRiskCheck" shape="square" icon-size="16px" checked-color="#57ccc2" >
        <span :class="{'disabled-cla': !riskForm.riskCheck}">{{comProps.riskName}}</span>
      </van-checkbox>
    </div>
    <div class="h-cell__form">
      <template v-for="(formItem, index) in formItems()">
        <van-col :key="index" :span="formItem.span || 12">
          <HYRender
            :component-ref="formItem.code + 'Ref'"
            :control="formItem.control"
            :config="formItem.config"
            :form="riskForm"
            :on="{
              input: (val) => {
                riskForm[formItem.code] = val
              }
            }"
          />
        </van-col>
      </template>
    </div>
  </div>
</template>
<script>
import AsyncValidator from 'async-validator'
import HYRender from '@/components/Render'
import HInput from './form-item/HInput'
import HSelect from './form-item/HSelect'
export default {
  name: 'Form',
  inheritAttrs: false,
  // eslint-disable-next-line vue/no-unused-components
  components: { HYRender, HInput, HSelect},
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    layout: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      riskForm:null,
      errors:null
    }
  },
  computed: {
    comProps () {
      return Object.assign({
        riskName: null,
        riskCode: null,
        parentCode: null,
        formItems: []
      }, this.layout)
    },
    parentRiskCheck(){
      if(!this.layout.parentCode){
        return false
      }
      if(this.layout.parentCode&&this.form[this.layout.parentCode]&&this.form[this.layout.parentCode].riskCheck){
        return false
      }
      this.cancelCheck()
      return true
    },
    descriptor(){
      const desc={}
      this.layout.formItems.forEach(item=>{
        desc[item.code] = (item.config && item.config.rules) || []
      })
      return desc
    }
  },
  watch: {
    riskForm:{
      handler (newVal, oldVal) {
        this.formItems().map(formItem => {
          if(formItem.config.val === newVal[formItem.code]){
            return true
          }
          formItem.config.val=newVal[formItem.code]
        })
      },
      deep:true
    }
  },
  created () {
    this.initForm()
  },
  methods: {
    initForm () {
      this.riskForm=this.form[this.comProps.riskCode]
      this.riskForm.riskName = this.comProps.riskName
      this.riskForm.riskCode = this.comProps.riskCode
      this.$set(this.riskForm, 'riskCheck', this.riskForm['riskCheck'] || false)
      this.comProps.formItems.forEach(formItem => {
        this.$set(this.riskForm, formItem.code, this.riskForm[formItem.code] || formItem.config.val || null)
      })
    },
    formItems () {
      return this.layout.formItems.filter(item => item.show)
    },
    clearValidate () {
      this.errors=null
    },
    cancelCheck(){
      this.riskForm.riskCheck = false
    },
    async validate () {
      return new Promise((resolve) => {
        new AsyncValidator(this.descriptor).validate(this.riskForm, { firstFields: true }, (errors, invalidFields) => {
          this.errors=errors
          if(errors){
            resolve(false)
          }else{
            resolve(true)
          }
        });
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
@import "@/assets/style/css/cell.scss";
.h-cell {
  padding: 10px;
}
.h-cell__form{
  padding: 10px 15px;
}
.disabled-cla {
  color: #c8c9cc;
}
</style>
