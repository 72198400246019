export default {
    methods: {
        initRiskVal (pageRiskForm) {
            const form = this.pageForm
            if (form.vehicle.vehicleUsage === '2') {
                // 车辆使用性质为家庭自用
                if (form.vehicle.vehicleUsageDetail === '01') {
                    // 非新能源 座位数判断
                    if (form.vehicle.seatCount < 9 || form.vehicle.seatCount === 9) {
                        if (form.vehicle.newEnergyFlag === '0') {
                            pageRiskForm.COMM0F01.riskCheck = true
                            pageRiskForm.COMM0F01.quantity = '7'
                            pageRiskForm.COMM0G01.riskCheck = true
                            pageRiskForm.COMM0G01.quantity = '1'
                            pageRiskForm.COMM0J01.riskCheck = true
                            pageRiskForm.COMM0J01.quantity = '1'
                        } else {
                            // 新能源
                            pageRiskForm.COMM1F01.riskCheck = true
                            pageRiskForm.COMM1F01.quantity = '7'
                            pageRiskForm.COMM1G01.riskCheck = true
                            pageRiskForm.COMM1G01.quantity = '1'
                            pageRiskForm.COMM1J01.riskCheck = true
                            pageRiskForm.COMM1J01.quantity = '1'
                        }
                    } else {
                        if (form.vehicle.newEnergyFlag === '0') {
                            // 非新能源
                            pageRiskForm.COMM0F01.quantity = '7'
                        } else {
                            // 新能源
                            pageRiskForm.COMM1F01.quantity = '7'
                        }
                    }
                } else {
                    // 车辆使用性质为非家庭自用
                        // 非新能源
                    if (form.vehicle.seatCount < 10) {
                        if (form.vehicle.newEnergyFlag === '0') {
                            // 非新能源
                            pageRiskForm.COMM0F01.riskCheck = true
                            pageRiskForm.COMM0F01.quantity = '7'
                            pageRiskForm.COMM0G01.quantity = '1'
                            pageRiskForm.COMM0J01.quantity = '1'
                            pageRiskForm.COMM0G01.riskCheck = false
                            pageRiskForm.COMM0J01.riskCheck = false
                        } else {
                            // 新能源
                            pageRiskForm.COMM1F01.riskCheck = true
                            pageRiskForm.COMM1F01.quantity = '2'
                            pageRiskForm.COMM1G01.quantity = '1'
                            pageRiskForm.COMM1J01.quantity = '1'
                            pageRiskForm.COMM1G01.riskCheck = false
                            pageRiskForm.COMM1J01.riskCheck = false
                        }
                    } else if (form.vehicle.seatCount > 10 && form.vehicle.seatCount < 12) {
                        if (form.vehicle.newEnergyFlag === '0') {
                            // 非新能源
                            pageRiskForm.COMM0F01.riskCheck = true
                            pageRiskForm.COMM0F01.quantity = '2'
                            pageRiskForm.COMM0G01.quantity = '1'
                            pageRiskForm.COMM0J01.quantity = '1'
                        } else {
                            // 新能源
                            pageRiskForm.COMM1F01.riskCheck = true
                            pageRiskForm.COMM1F01.quantity = '2'
                            pageRiskForm.COMM1G01.quantity = '1'
                            pageRiskForm.COMM1J01.quantity = '1'
                        }
                    }
                }
            } else {
                if (form.vehicle.newEnergyFlag === '0') {
                    pageRiskForm.COMM0F01.quantity = '7'
                } else {
                    pageRiskForm.COMM1F01.quantity = '7'
                }
            }
            // 载客量11座以上不赠送（所有的不赠送）。载客量＞11；
            if (form.vehicle.seatCount > 11) {
                if (form.vehicle.newEnergyFlag === '0') {
                    pageRiskForm.COMM0F01.quantity = '7'
                } else {
                    pageRiskForm.COMM1F01.quantity = '7'
                }
            }
        }
    }
}