<template>
  <div class="risk-input h-flex h-flex-nowrap" :class="{'disabled-cla': !form.riskCheck}">
      <input
        v-model="val"
        v-bind="{ ...elProps }"
        :placeholder="elProps.placeholder"
        :disabled="!form.riskCheck || elProps.disabled"
        @input="handleEvent('input')"
        @blur="handleEvent('blur')"
        @change="handleEvent('change')" />
      <span class="unit" v-if="config.unit">{{ config.unit }}</span>
      <span class="append" v-if="config.append">{{ config.append }}</span>
  </div>
</template>
<script>
export default {
  name: 'HInput',
  inheritAttrs: false,
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {
          pageData: {}
        }
      }
    }
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    },
    form:{
      type: Object,
      default: () => {}
    }
  },
  watch: {
    'config.val': {
      handler (newVal, oldVal) {
        this.val = newVal
      }
    }
  },
  data () {
    return {
      val: null,
      riskForm: {}
    }
  },
  computed: {
    elProps () {
      return Object.assign({
        val: null,
        placeholder: '请输入',
        readonly: false,
        disabled: false,
        clearable: true,
        blurEvent: null,
        changeEvent: null
      }, this.config)
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.val = this.config.val
    },
    handleEvent (eventType) {
      if (eventType === 'input') {
        if (this.val && this.config.toUpperCase) {
          this.val = this.val.toUpperCase()
        }
        this.config.val = this.val
        this.$emit('input', this.val)
      }
      const event = this.config[eventType + 'Event']
      if (event) {
        if (typeof event=== 'string' || event instanceof String) {
          // eslint-disable-next-line no-new-func
          new Function(`return function(ctx) { ${event} }`)()(this)
        } else if (event instanceof Function) {
          event(this)
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
.risk-input{
  input{
    display: block;
    box-sizing: border-box;
    width: 100%;
    min-width: 0;
    margin: 0;
    padding: 0;
    color: #323233;
    line-height: inherit;
    text-align: left;
    background-color: transparent;
    border: 0;
    resize: none;
  }
  .unit,.append{
    padding: 0 2px;
  }
  .unit{
    color: $primary-color;
  }
  input::-webkit-input-placeholder {
    color: #c8c9cc;
  }
  input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #c8c9cc;
  }
  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #c8c9cc;
  }
  input::-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #c8c9cc;
  }
}
.disabled-cla {
  input {
    color: #c8c9cc;
  }
}
</style>