<template>
  <div class="h-cell h-cell--block table" >
    <div class="h-cell__title">
      <van-checkbox v-model="riskForm.riskCheck" :disabled="parentRiskCheck" shape="square" icon-size="16px" checked-color="#57ccc2" >
        <div class="h-flex h-row-between h-col-center">
          <div :class="{'disabled-cla': !riskForm.riskCheck}">{{comProps.riskName}}</div>
          <div class="h-flex" @click.stop="showAdd">
            <van-button :disabled="!riskForm.riskCheck" plain round block type="primary" size="mini">新增设备</van-button>
            <div v-if="riskForm.riskCheck&&formList.length>0" class="h-text-nowrap h-p-l-5">X {{formList.length}}个</div>
          </div>
        </div>
      </van-checkbox>
    </div>
    <div v-if="riskForm.riskCheck&&formList.length>0" class="h-m-t-5">
      <table class="table">
        <tr>
          <th>序号</th>
          <th>设备名称</th>
          <th>实际价值</th>
          <th>操作</th>
        </tr>
        <tr v-for="(item,index) in formList" :key="index">
          <td>{{index+1}}</td>
          <td>{{item.toolName}}</td>
          <td>{{item.actualValue}}</td>
          <td>
            <span class="h-primary-color h-m-r-5" @click="viewOpt(item)">查看</span>
            <span class="h-danger-color" @click="delOpt(index)">删除</span>
          </td>
        </tr>
      </table>
    </div>
    <div class="tableForm" id="tablePopup">
      <van-popup v-model="pageData.popupShow" round closeable position="bottom" teleport="#tablePopup" >
        <div class="title">
          新增设备信息录入
        </div>
        <div class="content">
          <HYForm
            ref="formRef"
            :fmCode="riskForm.riskCode"
            :config="formObj.config"
            :form="formObj.item">
          </HYForm>
          <div class="btn">
            <div class="fixed h-flex h-row-around h-col-center">
              <van-button round plain type="primary" @click="closeOpt">取消</van-button>
              <van-button round type="primary" @click="addOpt">确认</van-button>
            </div>
          </div>
        </div>
      </van-popup>
      <van-popup v-model="pageData.popupViewShow" round closeable position="bottom" teleport="#tablePopup" >
        <div class="title">
          新增设备信息查看
        </div>
        <div class="viewContent">
          <HYFormView
            :fmCode="riskForm.riskCode"
            :config="formObj.config"
            :form="formObj.viewItem">
          </HYFormView>
        </div>
      </van-popup>
    </div>
  </div>
</template>
<script>
import HYForm from "@/components/Form.vue";
import HYFormView from "@/components/FormView.vue";
import { cloneDeep } from "lodash";
export default {
  name: 'Device',
  inheritAttrs: false,
  components: {HYForm,HYFormView},
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    layout: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      pageData:{
        popupShow:false,
        popupViewShow:false
      },
      riskForm:null,
      formObj:{
        config:{},
        item:{},
        viewItem:{},
      },
      formList:[]
    }
  },
  computed: {
    comProps () {
      return Object.assign({
        riskName: null,
        riskCode: null,
        parentCode: null,
        formItems: []
      }, this.layout)
    },
    parentRiskCheck(){
      if(!this.comProps.parentCode){
        return false
      }
      if(this.comProps.parentCode&&this.form[this.comProps.parentCode]&&this.form[this.comProps.parentCode].riskCheck){
        return false
      }
      this.cancelCheck()
      return true
    }
  },
  created () {
    this.initForm()
    this.initTableForm()
  },
  methods: {
    initForm () {
      this.riskForm=this.form[this.comProps.riskCode]
      this.riskForm.riskName = this.comProps.riskName
      this.riskForm.riskCode = this.comProps.riskCode
      this.$set(this.riskForm, 'riskCheck', this.riskForm['riskCheck'] || false)
      if(this.riskForm.deviceList){
        this.formList=this.riskForm.deviceList
      }
    },
    initTableForm() {
      const config={
        fmName: this.comProps.riskName,
        fmCode: this.comProps.riskCode,
        show: true,
        labelWidth: "7em",
        formItems:this.comProps.formItems.filter(item => item.show)
      }
      this.formObj.config=config
    },
    clearValidate () {
    },
    cancelCheck(){
      this.riskForm.riskCheck = false
    },
    validate () {
      if(this.riskForm.deviceList&&this.riskForm.deviceList.length>0){
        return true
      }
      return false
    },
    closeOpt(){
      this.pageData.popupShow = false
    },
    viewOpt(item){
      this.formObj.viewItem = cloneDeep(item)
      this.pageData.popupViewShow = true
    },
    delOpt(index){
      this.formList.splice(index, 1);
      this.riskForm.deviceList=this.formList 
    },
    showAdd(){
      if(!this.riskForm.riskCheck){
        return false
      }
      this.comProps.formItems.forEach(formItem => {
        this.$set(this.formObj.item, formItem.prop, formItem.config.default)
      })
      this.pageData.popupShow = true
    },
    async addOpt(){
      const validate = await this.$refs.formRef.validateForm()
      if(validate){
        this.formList.push(cloneDeep(this.formObj.item))
        this.riskForm.deviceList=this.formList
        this.pageData.popupShow = false
      }else{
        this.$notify("请完善信息")
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
@import "@/assets/style/css/cell.scss";
.h-cell {
  padding: 10px;
}
.disabled-cla {
  color: #c8c9cc;
}
::v-deep .van-checkbox__label{
  width: 100%;
}
.table{
  width: 100%;
  border-collapse: collapse;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  flex: 1;
  background-color: #fff;
  font-size: 14px;
  color: #606266;
  th,td{
    border:1px solid #ebeef5;
    padding:6px 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    vertical-align: middle;
    position: relative;
    text-align: center;
  }
  th:first-child{
    width: 40px;
  }
  th:last-child{
    width: 70px;
  }
  .tableForm{
    .van-popup{
      height: 70vh;
    }
    .title{
      height: 50px;
      line-height: 50px;
      text-align: center;
      white-space: nowrap;
      font-size: $font-size-xl;
      font-weight: $font-weight-bold-lg;
      border-bottom: 1px dashed $primary-color;
    }
    .viewContent{
      position: relative;
      height: calc(100% - 50px);
      overflow: scroll;
    }
    .content{
      position: relative;
      height: calc(100% - 50px - 55px);
      overflow: scroll;
      .btn{
        position: relative;
        .van-button{
          width: 100px;
        }
        .fixed{
          height: 45px;
          position: fixed; 
          left: 0;
          right: 0;
          bottom: 0;
          background: #fff;
          padding: 5px 0;
          // padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
          // padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
        }
      }
    }
  }
}
</style>